<template>
    <admin-dashboard-layout :loading="loading">
        <v-container>
            <v-form>
                <v-card class="mx-15 px-15">
                    <v-card-title>
                        <v-row>
                            <v-col>
                                {{tag.name}}
                            </v-col>
                            <v-col class="text-right">
                                <v-btn small :to="{name: 'admin.tags.index'}">Return </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-row>
                                <v-col>
                                    <v-text-field outlined dense label="Name"  v-model="tag.name"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <h3>Logo <v-btn x-small v-if="tag.thumbnail_url" @click="tag.thumbnail_url = null">remove</v-btn> </h3>
                                    <avatar-edit
                                        :current_url="tag.thumbnail_url"
                                        @input="handleSave"
                                        v-model="tag.thumbnail_url"
                                    ></avatar-edit>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-select
                                        v-model="tag.industry_id"
                                        outlined
                                        dense
                                        label="Industry"
                                        item-text="name"
                                        item-value="id"
                                        :items="industries"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="text-center">
                                    <v-btn v-if="$route.params.id" @click="handleDelete" color="red">  Delete </v-btn>
                                </v-col>
                                <v-col class="text-center">
                                    <v-btn @click="handleSave">  Save </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-form>
        </v-container>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import Tag from "../../../models/Tag";
    import Industry from "@/models/Industry";
    import AvatarEdit from "@/components/AvatarEdit";
    import {mapMutations} from "vuex";

    export default {
        name: "Form",
        components: {AdminDashboardLayout, AvatarEdit},
        data: function () {
            return {
                tag: {},
                industries: [],
                loading: true,
            }
        },
        methods: {
            async handleSave() {
                const tag = new Tag(this.tag)
                this.tag = await tag.save()
                this.showSnackBar({color: 'success', timeout: 3000, text: 'Saved successfully'})
                if (this.tag.id && ! this.$route.params.id) {
                    await this.$router.push({name: 'admin.tags.show', params: {id: this.tag.id}})
                }
            },
            async handleDelete() {
                let tag = await Tag.find(this.$route.params.id)
                tag.delete()
                this.showSnackBar({color: 'success', timeout: 3000, text: 'Saved successfully'})
                this.$router.push({name: 'admin.tags.index'})
            },
            async getIndustries() {
                this.industries = await Industry.get();

                if (!this.tag.industry_id) {
                    this.tag.industry_id = this.industries[0].id
                }
            },
            ...mapMutations(['showSnackBar']),
        },
        async mounted() {
            await this.getIndustries();
            const tagId = this.$route.params.id;

            if (tagId) {
                this.tag = await Tag.find(tagId);
            }

            this.loading = false;
        }
    }
</script>
